import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Card } from "antd";
import whiteLogo from "../../Assets/logo-light.png";
import bear from "../../Assets/stuffed-bears.png";
import { Image } from "react-bootstrap";
import "./Pricing.scss";

const Pricing = () => {
  return (
    <>
      <Container fluid className="pricing-section" id="pricing">
        <Container className="pricing-content">
          {/* Title Section */}
          <Row>
            <div className="price-title">
              <h1 className="shaking center-text">
                UFO Claw Offers <strong>Out-of-This-World Value</strong>!
              </h1>
              <p>
                Get the best bang for your buck with our token packages. The more you buy, the more tokens you get to play and win amazing prizes!
              </p>
            </div>
          </Row>

          {/* Pricing Cards - Token Packages */}
          <Row className="equal-height-cards">
            {/* $10 Package */}
            <Col md={3} className="d-flex card-container">
              <Card className="box flex-fill">
                <section className="pricing-info">
                  <h1 className="center-text">$10 = 10 Tokens</h1>
                  <div className="pricing-details">
                    Perfect for a quick game session. Get 10 tokens for just $10 and start grabbing your favorite prizes!
                  </div>
                </section>
              </Card>
            </Col>

            {/* $20 Package */}
            <Col md={3} className="d-flex card-container">
              <Card className="box flex-fill">
                <section className="pricing-info">
                  <h1 className="center-text">$20 = 20 Tokens</h1>
                  <div className="pricing-details">
                    Double the tokens, double the fun! Enjoy 20 tokens for $20 and increase your chances of winning big.
                  </div>
                </section>
              </Card>
            </Col>

            {/* $50 Package (Best Value) */}
            <Col md={3} className="d-flex card-container">
              <Card className="box flex-fill">
                <section className="pricing-info">
                  <h1 className="center-text">$50 = 55 Tokens</h1>
                  <div className="pricing-details">
                    Best Value! Get 55 tokens for just $50. It’s the perfect choice for serious players who want more chances to win.
                  </div>
                </section>
              </Card>
            </Col>

            {/* $100 Package (Best Value) */}
            <Col md={3} className="d-flex card-container">
              <Card className="box flex-fill">
                <section className="pricing-info">
                  <h1 className="center-text">$100 = 120 Tokens</h1>
                  <div className="pricing-details">
                    Ultimate Value! Get 120 tokens for $100. More plays, more fun, and the best deal for those ready to go all in!
                  </div>
                </section>
              </Card>
            </Col>
          </Row>

          {/* Contact Card */}
          <Row className="equal-height-cards second-row">
            <Col md={6} className="d-flex card-container">
              <Card className="UFO-box flex-fill">
                <section className="contact-info">
                  <Image
                    src={whiteLogo}
                    alt="UFO Logo"
                    className="UFO-logo-pricing"
                  />
                  <h1>CONTACT US</h1>
                  <p className="price-package">
                    Have questions or need a custom package? Reach out to our team – we’re here to help you have a blast!
                  </p>
                  <a
                    href="mailto:hello@ufoclaw.com"
                    className="email contact-email"
                  >
                    hello@ufoclaw.com
                  </a>
                </section>
              </Card>
            </Col>

            <Col md={6} className="d-flex card-container">
              <section className="bear-box">
                <Image
                  src={bear}
                  alt="UFO Logo"
                />
              </section> 
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Pricing;
