import React from "react";
import "./globals.css";
import NavBar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import "../components/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toaster } from "react-hot-toast";

const Layout = ({ children }) => {
  return (
    <>
      <Toaster />
      <NavBar />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
