import HomeHero from "../components/Home/HomeHero/HomeHero";
// import styles from "./page.module.css";

import React from "react";
import HomeFeatures from "../components/Home/HomeFeatures/HomeFeatures";
import HomeIntro from "../components/Home/HomeIntro/HomeIntro";

const Home = () => {
  return (
    <div className="home-page">
      <HomeHero />
      <HomeFeatures />
      <HomeIntro />
    </div>
  );
};

export default Home;
