import Pricing from "../components/Pricing/Pricing";
import React, { Fragment } from "react";

const PricingPage = () => {
  return (
    <Fragment>
      <Pricing />
    </Fragment>
  );
};

export default PricingPage;
