import Location from "../components/Location/Location";
import React, { Fragment } from "react";

const PricingPage = () => {
  return (
    <Fragment>
      <Location />
    </Fragment>
  );
};

export default PricingPage;
