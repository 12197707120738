import Contact from "../components/Contact/Contact";
import React, { Fragment } from "react";

const ContactPage = () => {
  return (
    <Fragment>
      <Contact />
    </Fragment>
  );
};

export default ContactPage;
