"use client";
import React from "react";
import { Container } from "react-bootstrap";
import { Image } from "react-bootstrap"; 
import store from "../../Assets/store.png";
import "./Location.scss";

function Location() {
  return (
    <Container fluid className="location-container">
      <Container>
        <main className="location-main">
          <h1>
          We’re Landing Soon – Get Ready for the Fun!
          </h1>
          <Image className="location-img" src={store} alt="UFO Claw" />
          <div className="location-info">
            <span>UFO Claw is Coming Soon to a shopping mall near you! We’re preparing for takeoff, and the excitement is building. Stay tuned for updates on our grand opening date and location reveal – we can’t wait to bring our out-of-this-world arcade experience to you!
            </span>
            </div>
        </main>
      </Container>
    </Container>
  );
}

export default Location;
