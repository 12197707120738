"use client";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import stuffydog from "../../../Assets/stuffy-dog.png";
import clawmachine from "../../../Assets/claw-machine.png";

import { Image } from "react-bootstrap";

import "./HomeIntro.scss";

function HomeIntro() {
  return (
    <Container fluid className="home-about-section">
      <Container>
        <Row className="ultimate">
          <Col md={6} className="about-text">
            <h2>The Ultimate Grab-tastic Experience!</h2>
            <p>
            Step into a world where skill meets thrill, and every grab is filled with excitement! At UFO Claw, we’re all about the chase, the win, and the sheer joy of nabbing that perfect prize. With neon-lit machines, alien-themed decor, and a cosmic collection of goodies, it’s an arcade adventure that’s truly out of this world!
            </p>
          </Col>
          <Col md={4} className="about-images">
            <Image width={300} src={stuffydog} alt="stuffy dog" />
          </Col>
        </Row>

        <Row className="vibes">
          <Col md={4} className="about-images">
            <Image width={300} src={clawmachine} alt="stuffy dog" />
          </Col>
          <Col md={6} className="about-text">
            <p>
            We believe in good vibes, great prizes, and the idea that every play should feel like an adventure. So grab your space helmet (or your favorite plushie), and get ready to take on the challenge. It’s time to launch into a galaxy of fun at UFO Claw!
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default HomeIntro;
