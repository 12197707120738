import About from "../components/About/About";
import React, { Fragment } from "react";

const AboutPage = () => {
  return (
    <Fragment>
      <About />
    </Fragment>
  );
};

export default AboutPage;
