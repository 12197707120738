import React, { useState } from "react";
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import claw from "../../Assets/claw-animals.png";
import stuffy from "../../Assets/stuffed-animals.png";
import boy from "../../Assets/claw-boy.png";
import "./About.scss";

const About = () => {
  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <Container fluid className="about-section" id="about">
      <Container className="about-content">
        <h1 className="shaking center-text">ABOUT US</h1>

        <Row className="hero-section">
          <Col md={7} className="hero-text">
            <h1>The Story Behind UFO Claw</h1>
            <p>
              UFO Claw was created for anyone who loves the thrill of the grab, the suspense of the claw dropping, and the joy of walking away with a new prize (or three!). We’ve designed our arcade to be a playground for all ages – whether you’re here for a date night, a family outing, or just to prove your claw game skills to your friends.
            </p>
            <div className="about-button"></div>
          </Col>
          <Col md={5} className="hero-image">
            <img className="feature-icon" src={claw} alt="UFO Claw" />
          </Col>
        </Row>

        {/* Features Section with Accordion */}
        <Row className="features-section">
          <h1>Frequently Asked Questions (FAQ)</h1>
          <Col md={6} className="features-image">
            <img className="feature-team" src={boy} alt="UFO Claw" />
          </Col>
          <Col md={6}>
            <Accordion activeKey={activeKey}>
              <Card>
                <Accordion.Header onClick={() => handleToggle("0")}>
                  <span>Q: Do you offer discounts for group events?</span>
                </Accordion.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <p>
                      A: Absolutely! We love hosting birthday parties, corporate events, and group hangouts. Contact us for special rates and packages.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Accordion.Header onClick={() => handleToggle("1")}>
                  <span>Q: Are the machines skill-based or random?</span>
                </Accordion.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p>A: All of our claw machines are skill-based. With the right touch and a bit of practice, anyone can be a winner!</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Accordion.Header onClick={() => handleToggle("2")}>
                  <span>Q: What kinds of prizes can I win?</span>
                </Accordion.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <p>
                      A: Our prizes range from cute plushies and anime figures to high-tech gadgets and gift cards. We rotate our stock often, so there’s always something new to grab.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card>
                <Accordion.Header onClick={() => handleToggle("3")}>
                  <span>Q: Is there an age limit to play?</span>
                </Accordion.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <p>
                      A: No age limit here – everyone is welcome to play! Kids under 10 should be supervised by an adult, though (mostly so the adults don’t hog all the fun).
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>

        <div className="integration-section">
          <Container>
            <Row>
              <Col>
                <h2>We’re Landing Soon – Get Ready for the Fun!</h2>
                <p>
                  🚀 UFO Claw is Coming Soon to a shopping mall near you! We’re preparing for takeoff, and the excitement is building. Stay tuned for updates on our grand opening date and location reveal – we can’t wait to bring our out-of-this-world arcade experience to you!
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <Row className="hero-section onboarding-section">
          <Col md={7} className="hero-text">
            <h1>Get ready – the fun is about to land! 🛸✨</h1>
            <p>
              Join our mailing list and follow us on social media for exclusive sneak peeks, launch event invites, and a chance to win free plays on opening day!
            </p>
          </Col>
          <Col md={5} className="hero-image">
            <img className="feature-icon" src={stuffy} alt="UFO Claw" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default About;
