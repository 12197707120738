import React from "react";
import "./HomeHero.scss";
import { Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router
import logo from "../../../Assets/logo.png";
import { Image } from "react-bootstrap";

const HomeHero = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  return (
    <div className="hero-wrapper">
      <Container>
        <div className="hero-container">
        <video
          className="home-hero-video desktop"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src="home-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video
          className="home-hero-video mobile"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src="home-video-mobile.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>


          <div className="hero-overlay large-screen">
            <Image className="home-logo" src={logo} alt="claw machine" />
            <h1>Out of This World Fun!</h1>
            <p>
              Welcome to UFO Claw, where skill meets thrill, and you’re just one grab away from cosmic glory!
            </p>
            <div className="buttonRootDiv">
              <Button
                className="hear-demo-button"
                onClick={() => navigate("/about")}
              >
                ABOUT US
              </Button>
              <Button
                variant="outline"
                className="try-now-button"
                onClick={() => navigate("/contact")}
              >
                CONTACT
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HomeHero;
