/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import React, { useEffect, useRef } from "react";
import { Card } from "antd";
import { Container, Row, Col, Image } from "react-bootstrap";
import skill from "../../../Assets/skill.png";
import clawbear from "../../../Assets/claw-bear.png";
import ufoicon from "../../../Assets/ufo-icon.png";
import bear from "../../../Assets/bear.png";
import "./HomeFeatures.scss";

function HomeFeatures() {
  const upgradeImageRef = useRef(null);
  const cardRefs = useRef([]);

  useEffect(() => {
    const handleScrollAnimation = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate");
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleScrollAnimation, {
      threshold: 0.1,
    });

    if (upgradeImageRef.current) {
      observer.observe(upgradeImageRef.current);
    }

    cardRefs.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      if (upgradeImageRef.current) {
        observer.unobserve(upgradeImageRef.current);
      }
      cardRefs.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

  return (
    <Container fluid className="home-features-section">
      <Container>
        <Row className="align-items-center justify-content-center features-title pb-4">
          <h1 className="header1">Why You’ll Love UFO Claw:</h1>
        </Row>

        <Row className="justify-content-center left">
          <Col md={6}>
            <Card className="slide-up" ref={(el) => (cardRefs.current[0] = el)}>
              <h4>The Prizes Are Out of This World!</h4>
              <div className="flex alignCenter">
                <Image className="feature-icon" src={clawbear} alt="claw machine" />
                <p>
                  Our claw machines are filled with an ever-changing collection of adorable plush toys, trendy gadgets, and exclusive prizes you won’t find anywhere else.
                </p>
              </div>
            </Card>
          </Col>

          <Col md={6}>
            <Card className="slide-up" ref={(el) => (cardRefs.current[1] = el)}>
              <h4>Skill Meets Fun</h4>
              <div className="flex alignCenter">
                <Image className="feature-icon" src={skill} alt="skill" />
                <p>
                  Think you’ve got what it takes? Our machines are designed to reward skill and strategy. Can you grab the prize before anyone else does?
                </p>
              </div>
            </Card>
          </Col>

          <Col md={6}>
            <Card className="slide-up" ref={(el) => (cardRefs.current[2] = el)}>
              <h4>Themed Claw Machines for Every Mood</h4>
              <div className="flex alignCenter">
                <Image className="feature-icon" src={ufoicon} alt="ufo claw" />
                <p>
                  Feeling lucky? Try your hand at the Mystery UFO, where every grab is a surprise – you never know what you might win!
                </p>
              </div>
            </Card>
          </Col>

          <Col md={6}>
            <Card className="slide-up" ref={(el) => (cardRefs.current[3] = el)}>
              <h4>UFO Claw Happy Hour!</h4>
              <div className="flex alignCenter">
                <Image className="feature-icon" src={bear} alt="bear" />
                <p>
                  Join us for UFO Claw Happy Hour, where every play comes with an extra chance to win! It’s a little extra fun to make your visit even more exciting.
                </p>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default HomeFeatures;
