import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Card } from "antd";
import whiteLogo from "../../Assets/logo1.png";
import { Image } from "react-bootstrap";
import "./Contact.scss";

const Contact = () => {
  return (
    <>
      <Container fluid className="contact-section" id="contact">
        <Container className="contact-content">
          {/* Contact Title */}
          <Row>
            <div className="contact-title">
              <h1 className="shaking center-text">
                Get in Touch with <strong>UFO Claw</strong>!
              </h1>
              <p>
                Whether you have questions, want to share your amazing wins, or need help with anything, we’d love to hear from you! Reach out and let’s connect.
              </p>
            </div>
          </Row>

          {/* Contact Information Cards */}
          <Row className="equal-height-cards">
            {/* Email Contact Card */}
            <Col md={6} className="d-flex">
              <Card className="box flex-fill">
                <section className="contact-info">
                  <Image
                    src={whiteLogo}
                    alt="UFO Logo"
                    className="UFO-logo-contact"
                  />
                  <h1>Email Us</h1>
                  <p className="contact-details">
                    Got questions? Send us an email, and we’ll get back to you faster than a UFO sighting!
                  </p>
                  <a
                    href="mailto:hello@ufoclaw.com"
                    className="email contact-email"
                  >
                    hello@ufoclaw.com
                  </a>
                </section>
              </Card>
            </Col>

            {/* Social Media Contact Card */}
            <Col md={6} className="d-flex">
              <Card className="UFO-box flex-fill">
                <section className="social-info">
                  <h1>Follow Us</h1>
                  <p className="contact-details">
                    Stay up-to-date with our latest prizes, events, and claw machine tips. Follow us on social media and join the fun!
                  </p>
                  <ul className="social-links">
                    <li>
                      <a href="https://www.instagram.com/UFOClaw" target="_blank" rel="noopener noreferrer">
                        Instagram: @UFOClaw
                      </a>
                    </li>
                    <li>
                      <a href="https://www.tiktok.com/@UFOClaw" target="_blank" rel="noopener noreferrer">
                        TikTok: @UFOClaw
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/UFOClaw" target="_blank" rel="noopener noreferrer">
                        Facebook: UFO Claw
                      </a>
                    </li>
                  </ul>
                </section>
              </Card>
            </Col>
          </Row>

          {/* Location Card */}
          <Row className="equal-height-cards second-row">
            <Col md={12} className="d-flex">
              <Card className="box flex-fill">
                <section className="location-info">
                  <h1>We’re Coming Soon!</h1>
                  <p className="contact-details">
                    UFO Claw is landing at a shopping mall near you! Stay tuned for our grand opening date and location reveal. Join our mailing list for exclusive updates and sneak peeks.
                  </p>
                  <a
                    href="mailto:hello@ufoclaw.com"
                    className="email contact-email"
                  >
                    hello@ufoclaw.com
                  </a>
                </section>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Contact;
