import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../../Assets/logo.png";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom"; // Use React Router Link component
import burgerIcon from "../../Assets/burger-icon.png";

const NavBar = () => {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      updateNavbar(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        expand &&
        !document
          .getElementById("responsive-navbar-nav")
          ?.contains(event.target) &&
        !document
          .getElementById("navbar-toggle")
          ?.contains(event.target)
      ) {
        updateExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [expand]);

  return (
    <>
      <Navbar
        expanded={expand}
        fixed="top"
        expand="md"
        className={`navbar ${navColour ? "navbar-scroll" : ""}`}
        style={{
          backgroundColor: "#ffffff",
          transition: "background-color 0.5s",
        }}
      >
        <Container>
          <Navbar.Brand href="/" className="d-flex align-items-center logo-container">
            <Image
              width={140}
              height={140}
              src={logo}
              className="img-fluid logo"
              alt="UFO Claw Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            id="navbar-toggle"
            aria-controls="responsive-navbar-nav"
            onClick={() => updateExpanded(!expand)}
          >
            <Image src={burgerIcon} alt="burger icon" width={30} height={30} />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Item>
                <Nav.Link as={Link} to="/" className="nav-link" onClick={() => updateExpanded(false)}>
                  Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/about" className="nav-link" onClick={() => updateExpanded(false)}>
                  About
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/pricing" className="nav-link" onClick={() => updateExpanded(false)}>
                  Pricing
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/contact" className="nav-link" onClick={() => updateExpanded(false)}>
                  Contact
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to="/location" className="contact-button" onClick={() => updateExpanded(false)}>
                  Location
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div style={{ paddingTop: "100px" }}>
        {/* Page content goes here */}
      </div>
    </>
  );
};

export default NavBar;
