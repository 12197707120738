import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const smoothScrollToTop = (duration = 300) => {
  const startPosition = window.pageYOffset;
  const startTime = performance.now();

  const easeOutQuad = (t) => t * (2 - t);

  const scroll = (currentTime) => {
    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / duration, 1);
    const ease = easeOutQuad(progress);

    window.scrollTo(0, startPosition * (1 - ease));

    if (progress < 1) {
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll);
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    smoothScrollToTop(400); // Adjust duration for faster/slower scroll (600ms is a good default)
  }, [pathname]);

  return null;
};

export default ScrollToTop;
